import { useContext, useEffect, useState } from "react";
import "./Home.css";
import { ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import ProductsContext from "../../context/Products/ProductsContext";

const Home = () => {
  const [spinner, setSpinner] = useState(false);
  const [destination, setDestination] = useState('');
  const { deleteProductState, products, stock, deleteStockState } = useContext(ProductsContext);
  let navigate = useNavigate();

  const handleNavigate = (e) => {
    setSpinner(true);
    setDestination(e.target.parentElement.id);
  }

  useEffect(() => {
    if(spinner){
      setTimeout(() => {
        setSpinner(false);
        navigate(`/${destination}`)
      }, 500);
    }
  }, [spinner])

  useEffect(() => {
    //Para que se actualice con la DB cada vez que vuelvo al Home.
    if(products.length !== 0){
      deleteProductState();
    }
    if(stock.length !== 0){
      deleteStockState();
    }
  }, [])

  return (
    <>
      {
        spinner ? <div className="form-spinner"><Spinner /></div> : null
      }
      <ListGroup className="admin-list-style">
        <ListGroup.Item className="home-items" id="stock" ><div className="op-box" onClick={handleNavigate}></div>Entrada de Stock</ListGroup.Item>
        <ListGroup.Item className="home-items" id="stockout" ><div className="op-box" onClick={handleNavigate}></div>Salida de Stock Extraordinaria</ListGroup.Item>
        <ListGroup.Item className="home-items" id="stockview" ><div className="op-box" onClick={handleNavigate}></div>Movimiento de Stock</ListGroup.Item>
        <ListGroup.Item className="home-items" id="updateproductdata" ><div className="op-box" onClick={handleNavigate}></div>Ver / Actualizar Datos de los Productos</ListGroup.Item>
        <ListGroup.Item className="home-items" id="productentry" ><div className="op-box" onClick={handleNavigate}></div>Agregar Nuevo Producto</ListGroup.Item>
        <ListGroup.Item className="home-items" id="vouchers"><div className="op-box" onClick={handleNavigate}></div>Administración de Cupones</ListGroup.Item>
        {/* <ListGroup.Item className="home-items" id="references"><div className="op-box" onClick={handleNavigate}></div>Programa de Referencias</ListGroup.Item> */}
        <ListGroup.Item className="home-items" id="orders"><div className="op-box" onClick={handleNavigate}></div>Pantalla de pedidos</ListGroup.Item>
        <ListGroup.Item className="home-items" id="userorders"><div className="op-box" onClick={handleNavigate}></div>Pedidos por Usuario</ListGroup.Item>
        <ListGroup.Item className="home-items" id="consumption"><div className="op-box" onClick={handleNavigate}></div>Consumo Semanal</ListGroup.Item>
        <ListGroup.Item className="home-items" id="delivery"><div className="op-box" onClick={handleNavigate}></div>Limites de Delivery</ListGroup.Item>
        <ListGroup.Item className="home-items" id="logistic"><div className="op-box" onClick={handleNavigate}></div>Logistica de Armado de Pedidos</ListGroup.Item>
        <ListGroup.Item className="home-items" id="message"><div className="op-box" onClick={handleNavigate}></div>Mensajes a Usuarios</ListGroup.Item>
        <ListGroup.Item className="home-items" id="addresses"><div className="op-box" onClick={handleNavigate}></div>Direcciones de Usuarios</ListGroup.Item>
      </ListGroup>
    </>
  );
};

export default Home;