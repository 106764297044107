import { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import AdminRoute from '../../routes/AdminRoute';
import './AddressViewPage.css';
import { Spinner, Table } from 'react-bootstrap';
import PopUp from '../../components/PopUp/PopUp';
import axiosClient from '../../settings/axiosClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const AddressViewPage = () => {
  const [spinner, setSpinner] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [moreDetails, setMoreDetails] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [indexForEdit, setIndexForEdit] = useState(-1);
  const [editedRows, setEditedRows] = useState([]);

  const getData = async () => {
    try {
      const usersId = await axiosClient.get('/users/userid');
      const addreses = await axiosClient.get('/address/alladdresses');
      if(usersId.status === 200 && addreses.status === 200){
        setAddresses(addreses.data.map(address => ({
          ...address, 
          user: usersId.data.find(user => user._id === address.userId)?.userEmail, 
          add: `${address.street ?? ""}${address.number?.length !== 0 ? ` ${address.number}` : ""}${address.neighbour?.length !== 0 ? ` (${address.neighbour})` : ""}, ${address.town}, ${address.province}`,
          gmapLink: address.gmapLink ?? "",
          adminDetails: address.adminDetails ?? "",
          zone: address.zone ?? ""
        })).filter(address => address.user));
      }
    } catch (error) {
      setErrors({server: 'Error en el servidor, intente nuevamente.'});
    }
  }

  const updateAddressData = async (index, addressId, gmapLink, adminDetails, zone) => {
    try {
      setSpinner(true);
      const response = await axiosClient.post('/address/updatedata', {addressId, gmapLink, adminDetails, zone});
      if(response.status === 200){
        const addressArrayUpdated = addresses.map((address, i) => {
          if(i === index){
            return {...address, gmapLink, adminDetails, zone};
          }
          return address;
        })
        setAddresses(addressArrayUpdated);
        setEditedRows(editedRows.filter(row => row !== index));
        setSuccess(true);
      }
    } catch (error) {
      setErrors({server: 'Error en el servidor, intente nuevamente.'});
    }
  }

  const handleKeyDown = (e) => {
    if(e.keyCode === 27){
      e.target.value = "";
      e.target.blur();
    }
  }

  const errorPopUpFunction = () => {
    setErrorPopUp(false); 
    setErrors({});
  }

  const handleChange = (e) => {
    if(!editedRows.includes(indexForEdit)) setEditedRows([...editedRows, indexForEdit]);
    const addressesUpdated = addresses.map((address, index) => {
      if(index === indexForEdit){
        return {
          ...address,
          [e.target.name]: e.target.value
        }
      }
      return address;
    })
    setAddresses(addressesUpdated);
  }

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    if(success){
      setSpinner(false);
      setSuccess(false);
      setSuccessPopUp(true);
    }
  }, [success])

  useEffect(() => {
    if(Object.keys(errors).length !== 0){
      setSpinner(false);
      setErrorPopUp(true);
    }
  }, [errors])

  return (
    <Layout>
      <AdminRoute>
        <div className='addressViewPage-style pt-5'>
          {
            spinner ? <div className="form-spinner"><Spinner /></div> : null
          }
          <div>
            <span className='ms-3 pointer' onClick={() => setMoreDetails(!moreDetails)}>Ver {moreDetails ? "Menos" : "Mas"} Detalles</span>
          </div>
          <Table striped bordered className='addressViewTable-style mt-2'>
            <thead>
              <tr>
                <th>Usuario</th>
                <th className={moreDetails ? "" : "d-none"}>Calle</th>
                <th className={moreDetails ? "" : "d-none"}>N°</th>
                <th className={moreDetails ? "" : "d-none"}>Barrio</th>
                <th className={moreDetails ? "" : "d-none"}>Local</th>
                <th className={moreDetails ? "" : "d-none"}>Provincia</th>
                <th className={moreDetails ? "" : "d-none"}>Departamento</th>
                <th className={moreDetails ? "" : "d-none"}>Comentario</th>
                <th>Direcc.</th>
                <th>G. Maps</th>
                <th>Detalles</th>
                <th>Zona</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                addresses.sort((a, b) => {
                  if(a.user < b.user){
                    return -1
                  } else if(a.user > b.user){
                    return 1
                  }else {
                    return 0
                  }
                }).map((address, index) => (
                  <tr key={index} className={editedRows.includes(index) ? "border-bottom border-2 border-warning" : ""}>
                    <td>{address.user.split('@')[0]}</td>
                    <td className={moreDetails ? "" : "d-none"}>{address.street}</td>
                    <td className={moreDetails ? "" : "d-none"}>{address.number}</td>
                    <td className={moreDetails ? "" : "d-none"}>{address.neighbour}</td>
                    <td className={moreDetails ? "" : "d-none"}>{address.town}</td>
                    <td className={moreDetails ? "" : "d-none"}>{address.province}</td>
                    <td className={moreDetails ? "" : "d-none"}>{address.apartment}</td>
                    <td className={moreDetails ? "" : "d-none"}>{address.comments}</td>
                    <td>{address.add}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center h-100 input-group">
                        <input autoComplete="off" name='gmapLink' onFocus={() => setIndexForEdit(index)} onBlur={() => setIndexForEdit(-1)} className="form-control wpx-100 input-noborder" type="text" onKeyDown={handleKeyDown} value={address.gmapLink} onChange={handleChange} />
                      </div> 
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center h-100 input-group">
                        <input autoComplete="off" maxLength={200} name='adminDetails' onFocus={() => setIndexForEdit(index)} onBlur={() => setIndexForEdit(-1)} className="form-control wpx-100 input-noborder" type="text" onKeyDown={handleKeyDown} value={address.adminDetails} onChange={handleChange} />
                      </div> 
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center h-100 input-group w-40px">
                        <input autoComplete="off" name='zone' maxLength={2} onFocus={() => setIndexForEdit(index)} onBlur={() => setIndexForEdit(-1)} className="form-control wpx-100 input-noborder" type="text" onKeyDown={handleKeyDown} value={address.zone} onChange={handleChange} />
                      </div> 
                    </td>
                    <td>
                      <FontAwesomeIcon className='pointer' icon={faPaperPlane} onClick={() => updateAddressData(index, addresses[index]._id, addresses[index].gmapLink, addresses[index].adminDetails, addresses[index].zone)} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
          <PopUp popUp={errorPopUp} setPopUp={setErrorPopUp} popUpTitle={"ERROR"} popUpText={Object.values(errors).join(', ')} redBorder={true} popUpBtnFunction={errorPopUpFunction} popUpBtnText={"OK"} noWayOfClose={true} />
          <PopUp popUp={successPopUp} greenBorder={true} setPopUp={setSuccessPopUp} popUpTitle={"Datos Almacenados"} popUpText={"Dirección Actualizada con éxito."} popUpBtnFunction={() => setSuccessPopUp(false)} popUpBtnText={"OK"} />
        </div>
      </AdminRoute>
    </Layout>
  );
};

export default AddressViewPage;