import { useContext, useEffect, useState } from "react";
import { Table, Button, Form } from "react-bootstrap";
import './AddProductTable.css'
import { useNavigate } from "react-router-dom";
import readForm from "../../helpers/readForm";
import ProductsContext from "../../context/Products/ProductsContext";
import SpinnerLogic from "../SpinnerLogic/SpinnerLogic";
import PopUp from "../PopUp/PopUp";
import { UNIT_MAX, UNIT_MIN } from "../../constants";

const AddProductTable = () => {
  const [rowNumber, setRowNumber] = useState(1);
  const [productsAdded, setProductsAdded] = useState([]);
  const [productsNotAdded, setProductsNotAdded] = useState([]);
  const [errors, setErrors] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  const { postNewMarketProduct } = useContext(ProductsContext);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  let navigate = useNavigate();
  let arrayRowNumber = [];

  for(let i = 0; i < rowNumber; i++){
    arrayRowNumber.push(i)
  }

  const deleteRow = () => {
    let i = rowNumber;
    i--;
    setRowNumber(i)
  }

  const addRowFunction = (e, item) => {
    if(e.keyCode === 9){
      if(item === rowNumber-1){
        let i = rowNumber;
        i++;
        setRowNumber(i);
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(spinner){
      return;
    }
    setSpinner(true);
    document.getElementById('overlay').focus();
    const arrayProductsSup = readForm(rowNumber);
    const postErrors = await postNewMarketProduct(arrayProductsSup);
    if(Object.keys(postErrors).length > 1){
      setErrors(postErrors);
      const arrayProductsNotAdded = arrayProductsSup.filter(product => Object.keys(postErrors).includes(product.productName)); //Filtro los productos que no se agregaron
      setProductsNotAdded(arrayProductsNotAdded);
      const arrayProductsAdded = arrayProductsSup.filter(product => !Object.keys(postErrors).includes(product.productName)); //Filtro los productos que SI se agregaron
      setProductsAdded(arrayProductsAdded);
      return;
    }
    
    setProductsAdded(arrayProductsSup);
    setSuccess(true);
  }

  useEffect(() => {
    if(productsNotAdded.length !== 0){
      setRowNumber(productsNotAdded.length);
      for(let i=0; i<productsNotAdded.length; i++){
        document.getElementById(`${i}0`).value = productsNotAdded[i].productName;
        document.getElementById(`${i}1`).value = productsNotAdded[i].wholePrice;
        document.getElementById(`${i}2`).value = productsNotAdded[i].unit;
        document.getElementById(`${i}3`).value = productsNotAdded[i].wholeQuantity;
        document.getElementById(`${i}4`).value = productsNotAdded[i].category;
      }
    }
    }, [productsNotAdded])

  //MANEJO LOS ERRORES DESDE ACA Y NO DESDE SPINNERLOGIC
  useEffect(() => {
    if(Object.keys(errors).length > 1){
      setTimeout(() => {
        setSpinner(false);
        setErrorPopUp(true);
      }, 1000)
    }
  }, [errors])

  return (
    <>
      <SpinnerLogic spinner={spinner} setSpinner={setSpinner} success={success} setSuccess={setSuccess} errors={{}} functionAfterSuccess={() => setSuccessPopUp(true)} popUpError={false} />
      <Form onSubmit={handleSubmit} className="m-5">
        <Table striped bordered className="addProduct-table-style">
          <thead>
            <tr>
              <th>Producto</th>
              <th className="text-center">Unidad</th>
              <th className="text-center">Categoría</th>
              <th className="text-center">Unidad MÍn.</th>
              <th className="text-center">Incremento</th>
              <th className="text-center">X</th>
            </tr>
          </thead>
          <tbody>
            {
              arrayRowNumber.map((item, index) => 
            <tr id={item} key={index}>
              <td><input id={`${item}0`} autoComplete="off" required className="form-control wpx" name="productName" type="text" /></td>
              <td>
              <Form.Select id={`${item}2`} className="wpx-80">
                <option value="K">Kg.</option>
                <option value="U">Un.</option>
              </Form.Select>
              </td>
              <td>
                <Form.Select id={`${item}4`} className="wpx-170">
                  <option value="fruits">Fruta</option>
                  <option value="veget">Verduras</option>
                  <option value="herbs">Hierbas y demás</option>
                  <option value="dried">Frutos secos</option>
                </Form.Select>
              </td>
              <td><input id={`${item}1`} autoComplete="off" required className="form-control wpx-100" name="unMin" type="Number" min={UNIT_MIN} max={UNIT_MAX} /></td>
              <td><input id={`${item}3`} autoComplete="off" required className="form-control wpx-100" step=".01" name="delta" type="Number" min={UNIT_MIN} max={UNIT_MAX} onKeyDown={(e) => addRowFunction(e, item)} /></td>
              <td><Button className={item === rowNumber-1 ? (item === 0 ? 'd-none' : null) : 'd-none'} variant="outline-danger" onClick={deleteRow}>X</Button></td>
            </tr>
              )
            }
            <tr>
              <td colSpan={6}><Button type="submit" className="w-100" variant="outline-success">Agregar Producto/s</Button></td>
            </tr>
          </tbody>
        </Table>
      </Form>
      <PopUp popUp={errorPopUp} redBorder={true} setPopUp={setErrorPopUp} closeBtn={false} popUpBtnFunction={() => setErrorPopUp(false)} popUpBtnText={"OK"}>
        
        {
          Object.keys(errors).length > 1 ? (
            <div>
              <h4 className="text-danger">ERROR</h4>
              <p>Error al cargar el/los producto/s: <span className="fw-bold text-danger">{Object.keys(errors).filter(product => product !== "productsAddedCorrectly").join(', ')}</span>. Revisar el/los precios, o que el/los nombres no coincidan con alguno de los productos de la Base de Datos.</p>
            </div>
          ) : null
        }
        {
          Object.keys(errors).length !== 0 &&  errors.productsAddedCorrectly?.length !== 0 ? (
            <div>
              <h4 className="text-success">Productos Agregados</h4>
              <p>Productos agregados: <span className="fw-bold text-success">{errors.productsAddedCorrectly?.join(', ')}</span></p>
            </div>
          ) : null
        }
      </PopUp>
      <PopUp popUp={successPopUp} greenBorder={true} setPopUp={setSuccessPopUp} popUpTitle={"Productos Agregados Correctamente"} popUpText={productsAdded.map(item => item.productName).join(', ')} popUpBtnFunction={() => navigate('/home')} popUpBtnText={"OK"} noWayOfClose={true} />
    </>
  );
};

export default AddProductTable;
